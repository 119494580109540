import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useUserData from "./hooks/useUserData";
import { protectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";

function App() {
	global.console.warn = () => {};
	global.console.error = () => {};
	const user = useUserData();

	return (
		<React.Fragment>
			<Routes>
				{user &&
					protectedRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={<Authmiddleware>{route.component}</Authmiddleware>}
							key={idx}
							exact={true}
						/>
					))}

				{publicRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={<>{route.component}</>}
						key={idx}
						exact={true}
					/>
				))}

				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</React.Fragment>
	);
}

export default App;
