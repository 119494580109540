import { Tooltip as ReactTooltip } from "react-tooltip";

const Word = ({ text, definition, color, id }) => {
	const wordStyle = {
		color: color,
	};

	return (
		<>
			<span
				className="word"
				style={wordStyle}
				data-tooltip-id={`tooltip-${id}`}
			>
				{text + " "}
			</span>
			<ReactTooltip id={`tooltip-${id}`} place="bottom" content={definition} />
		</>
	);
};

export default Word;
