import React from "react";
import { Navbar, NavbarBrand, Button } from "reactstrap";

function Header(args) {
	return (
		<div>
			<Navbar {...args} className="justify-content-between">
				<NavbarBrand href="/">Content Checker</NavbarBrand>
				<Button
					color="danger"
					outline
					className="ml-auto "
					onClick={() => {
						localStorage.removeItem("authUserWordTracker");
						window.location.replace("/");
					}}
				>
					Logout
				</Button>
			</Navbar>
		</div>
	);
}

export default Header;
