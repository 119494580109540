import React, { useState } from "react";

import {
	Row,
	Col,
	CardBody,
	Card,
	Alert,
	Container,
	Form,
	Input,
	FormFeedback,
	Label,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import useUserData from "../../hooks/useUserData";
import axios from "../../helpers/axios";
import Loader from "../../components/Loader";
const Login = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const user = useUserData();
	if (user) window.location.replace("/");
	//meta title
	document.title = "Login | Quiz App";

	const validation = useFormik({
		// enableReinitialize : use this  flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string().required("Please Enter Your Email"),
			password: Yup.string().required("Please Enter Your Password"),
		}),
		onSubmit: async (values) => {
			setError(null);
			setIsLoading(true);
			try {
				const { data } = await axios.post("/user/login", values);
				if (data) {
					localStorage.setItem("authUserWordTracker", JSON.stringify(data));
					window.location.replace("/");
				}
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				if (error.response && error.response.status === 400) {
					setError(error.response.data.msg);
				} else {
					setError("Something went wrong");
				}
				setIsLoading(false);
			}
		},
	});

	return (
		<React.Fragment>
			<div className="account-pages my-5 pt-sm-5">
				{isLoading && <Loader />}
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card className="overflow-hidden">
								<div className="bg-dark-subtle">
									<Row>
										<Col xs={7}>
											<div className="text-dark p-4">
												<h5 className="text-dark">Welcome Back !</h5>
												<p>Sign in to continue.</p>
											</div>
										</Col>
										<Col className="col-5 align-self-end"></Col>
									</Row>
								</div>
								<CardBody className="pt-0">
									<div className="p-2">
										<Form
											className="form-horizontal"
											onSubmit={(e) => {
												e.preventDefault();
												validation.handleSubmit();
												return false;
											}}
										>
											{error ? <Alert color="danger">{error}</Alert> : null}

											<div className="mb-3">
												<Label className="form-label">Email</Label>
												<Input
													name="email"
													className="form-control"
													placeholder="Enter email"
													type="email"
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.email || ""}
													invalid={
														validation.touched.email && validation.errors.email
															? true
															: false
													}
												/>
												{validation.touched.email && validation.errors.email ? (
													<FormFeedback type="invalid">
														{validation.errors.email}
													</FormFeedback>
												) : null}
											</div>

											<div className="mb-3">
												<Label className="form-label">Password</Label>
												<Input
													name="password"
													value={validation.values.password || ""}
													type="password"
													placeholder="Enter Password"
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													invalid={
														validation.touched.password &&
														validation.errors.password
															? true
															: false
													}
												/>
												{validation.touched.password &&
												validation.errors.password ? (
													<FormFeedback type="invalid">
														{validation.errors.password}
													</FormFeedback>
												) : null}
											</div>

											<div className="mt-3 d-grid">
												<button
													className="btn btn-primary btn-block"
													type="submit"
												>
													Log In
												</button>
											</div>
										</Form>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Login;
