import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Collapse } from "react-bootstrap";
import axios from "../../helpers/axios";
import Word from "./Word";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Swal from "sweetalert2";
const CenteredForm = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [extraWords, setExtraWords] = useState("");
	const [blogText, setBlogText] = useState("");
	const [sheetID, setSheedID] = useState("");
	const [sheets, setSheets] = useState([]);
	const [responseBlog, setResponseBlog] = useState([]);
	const [isFormCollapsed, setIsFormCollapsed] = useState(false); // State for collapse

	const showErrorAlert = () => {
		Swal.fire({
			title: "Error!",
			text: "Something went wrong!",
			icon: "error",
			confirmButtonText: "Reload Page",
			cancelButtonText: "Close",
			backdrop: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.reload();
			}
		});
	};

	const getSheetsFromDrive = async () => {
		try {
			const { data } = await axios.get("/sheets");
			setSheets(data);
			setIsLoading(false);
		} catch (error) {
			showErrorAlert();
			setIsLoading(false);
		}
	};

	const handleTextInputChange = (e) => {
		setExtraWords(e.target.value);
	};

	const handleBlogTextChange = (e) => {
		setBlogText(e.target.value);
	};

	const handleSheetChange = (e) => {
		setSheedID(e.target.value);
	};

	const handleSubmit = async (e) => {
		setIsLoading(true);
		setResponseBlog([]);
		try {
			e.preventDefault();
			const { data } = await axios.post("/sheets/check", {
				blog: blogText,
				extraWords: extraWords ? extraWords.split(",") : [],
				sheetID,
			});
			setResponseBlog(data);
			setIsLoading(false);
			setIsFormCollapsed(true);
		} catch (error) {
			console.log(error);
			showErrorAlert();
			setIsLoading(false);
		}
	};

	const handleUncollapseForm = () => {
		setIsFormCollapsed(!isFormCollapsed);
	};

	useEffect(() => {
		getSheetsFromDrive();
	}, []);

	useEffect(() => {
		// If responseBlog is empty, uncollapse the form
		if (responseBlog.length === 0) {
			setIsFormCollapsed(false);
		}
	}, [responseBlog]);

	return (
		<Container className="px-5">
			<Header />
			{isLoading && <Loader />}
			<Row>
				<Col lg={12}>
					{responseBlog.length ? (
						<Button variant="light" onClick={handleUncollapseForm}>
							{isFormCollapsed ? (
								<span>
									Show Form{" "}
									<i className="fa-solid fa-caret-down align-middle" />
								</span>
							) : (
								<span>
									Hide Form <i className="fa-solid fa-caret-up align-middle" />
								</span>
							)}
						</Button>
					) : null}
					<Collapse in={!isFormCollapsed}>
						<Form onSubmit={handleSubmit}>
							<Form.Group className="my-3" controlId="formTextArea">
								<Form.Label as={"h6"}>Content</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									value={blogText}
									onChange={handleBlogTextChange}
									placeholder="Enter blog text"
									required
								/>
							</Form.Group>

							<Form.Group className="my-3" controlId="formDropdown">
								<Form.Label as={"h6"}>Sheets</Form.Label>
								<Form.Control
									as="select"
									value={sheetID}
									required
									onChange={handleSheetChange}
								>
									<option value="">Select Sheet</option>
									{sheets?.map((sheet) => (
										<option value={sheet.id}>{sheet.name}</option>
									))}
								</Form.Control>
							</Form.Group>
							<Form.Group className="my-3" controlId="formTextInput">
								<Form.Label as={"h6"}>Extra Words</Form.Label>
								<Form.Control
									type="text"
									value={extraWords}
									onChange={handleTextInputChange}
									placeholder="Comma Separated Words (Optional)"
								/>
							</Form.Group>
							<Button variant="primary" type="submit" className="mt-2">
								Run
							</Button>
						</Form>
					</Collapse>
					{responseBlog.length ? (
						<>
							<h4 className="mt-2">Content with Matched Words</h4>

							<p className="blog ">
								{responseBlog?.map((word, index) => (
									<Word
										key={word.text}
										text={word.text}
										matched={word.matched}
										color={
											word?.row?.length > 0
												? word.row[1]
												: word.matched
												? "#A020F0"
												: null
										}
										definition={word?.row?.length > 0 ? word.row[0] : null}
										id={`tooltip-${index}`}
									/>
								))}
							</p>
						</>
					) : null}
				</Col>
			</Row>
		</Container>
	);
};

export default CenteredForm;
