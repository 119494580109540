import React from "react";

// Authentication related pages
import Login from "../pages/Authentication/Login";

import Blog from "../pages/Blog/Blog";

const protectedRoutes = [{ path: "/", component: <Blog /> }];

const publicRoutes = [{ path: "/login", component: <Login /> }];

export { protectedRoutes, publicRoutes };
